<template>
  <section
    class="menu"
  >
    <menu-overlay
      v-if="showOverlay"
      @mousedown.stop=""
      @mouseup.stop=""
      @touchstart.stop=""
      @touchend.stop=""
      @click.stop=""
    />
    <menu-icon-component
      v-show="showMenu"
      :show-esc="uiState.showEsc"
      :lock-menu="lockMenu"
      class="layout-menu pointer-events-auto"
      :exit-flashing="tutorialState.settings"
      :toggle-automatic="tutorialState.settings || keyOpenMenu"
      @on-toggle="onToggle"
      @settings-action="openSettings"
      @instructions-action="toggleInstructions(true)"
      @exit-action="exitOpen = true"
    />
    <instruction-component
      v-if="instructionsState.showInstructions"
      :is-mobile="isMobile"
      @close-instructions="toggleInstructions(false)"
    />
    <exit-discipline-modal
      v-if="exitOpen"
      :is-training="isTraining"
      :is-tutorial="isTutorial"
      class="pointer-events-auto"
      @yes="actionExit"
      @back="closeExit"
    />
    <settings-modal
      v-if="settingsOpen"
      class="pointer-events-auto"
      :show-graphics-settings="!isAppSsm"
      :show-aim-sensitivity="true"
      :show-one-finger="showOneFinger"
      :show-vibration="showVibration"
      @close="closeSettings"
      @settings-change="setThings"
      @mousedown.stop=""
      @mouseup.stop=""
      @touchstart.stop=""
      @touchend.stop=""
      @click.stop=""
    />
  </section>
</template>

<script lang="ts">
import {
  Menu,
  ExitDisciplineModal,
  SettingsModal,
  MenuOverlay,
  gameState,
  loadingCircleState,
  gameSettingsState
} from '@powerplay/core-minigames-ui-ssm'
import InstructionComponent from './InstructionComponent/index.vue'
import {
  game,
  requestManager,
  gameStats,
  settings,
  SettingsTypes,
  modes,
  tutorialManager,
  vibrations
} from '@powerplay/core-minigames'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import {
  instructionsState,
  uiState,
  type InputsState,
  inputsState,
  tutorialState,
  type InstructionsState,
  type UiState,
  type TutorialState
} from '@/stores'
import { defineComponent } from 'vue'
import { endManager } from '@/app/EndManager'
import { aimingDirectionManager } from '@/app/phases/AimPhase/AimingDirectionManager'

interface BasicComponentData {
  settingsOpen: boolean,
  exitOpen: boolean,
  showExit: boolean,
  keyOpenMenu: boolean,
  lockMenu: boolean,
  lockTween?: number,
  instructionsState: InstructionsState,
  uiState: UiState,
  inputsState: InputsState,
  tutorialState: TutorialState,
}

export default defineComponent({
  name: 'MenuSection',
  components: {
    MenuIconComponent: Menu,
    ExitDisciplineModal,
    SettingsModal,
    InstructionComponent,
    MenuOverlay
  },
  props: {
    isMobile: {
      type: Boolean,
      required: true
    },
    isAppSsm: {
      type: Boolean,
      required: true
    },
    showOverlay: {
      type: Boolean,
      required: false
    }
  },
  emits: ['toggle-ui'],
  data(): BasicComponentData {

    return {
      settingsOpen: false,
      exitOpen: false,
      showExit: true,
      keyOpenMenu: false,
      lockMenu: false,
      lockTween: undefined,
      instructionsState: instructionsState(),
      uiState: uiState(),
      inputsState: inputsState(),
      tutorialState: tutorialState(),
    }

  },
  computed: {
    showMenu() {

      return !(this.settingsOpen || this.exitOpen || instructionsState().showInstructions)

    },
    showOneFinger() {

      return this.isMobile && !modes.isTutorial()

    },
    showVibration() {

      return this.isMobile && vibrations.available

    },
    isTraining() {

      return modes.isTrainingMode()

    },
    isTutorial() {

      return modes.isTutorial()

    }
  },
  watch: {
    inputsState: {

      handler(value: InputsState) {

        if (value.exitPressed) {

          this.keyOpenMenu = !this.keyOpenMenu

          if (this.settingsOpen) {

            this.keyOpenMenu = false
            this.closeSettings()

          }
          if (this.exitOpen) {

            this.keyOpenMenu = false
            this.closeExit()

          }
          if (instructionsState().showInstructions) {

            this.keyOpenMenu = false
            this.toggleInstructions(false)

          }

        }

      },
      deep: true

    }
  },
  methods: {
    actionExit(): void {

      // ak sme uz ukoncovali, tak potom redirectujeme
      if (gameStats.wasExitedGame()) {

        requestManager.redirect()
        return

      }

      gameStats.setExitedGame(true)
      endManager.prematureEnded = true
      this.exitOpen = false

      this.onToggle(this.exitOpen)
      game.prematureFinishGame(disciplinePhasesManager.disciplinePrematureEnd)

      gameState().isPrematureEnd = true

      if (modes.isTutorial()) {

        loadingCircleState().isActive = true

      }

    },
    closeExit(): void {

      if (this.lockMenu) return
      this.exitOpen = false
      this.onToggle(this.exitOpen)

    },
    closeSettings(): void {

      if (this.lockMenu) return
      this.settingsOpen = false
      this.setThings()
      settings.saveValues()
      tutorialManager.blockInputsManually()
      this.onToggle(this.settingsOpen)

    },
    openSettings(): void {

      gameSettingsState().graphicsSettings = settings.getSetting(SettingsTypes.quality)

      this.settingsOpen = true

    },
    toggleInstructions(value: boolean): void {

      instructionsState().showInstructions = value
      if (!value) {

        this.onToggle(value)

      }

    },
    setThings(): void {

      settings.update('quality', gameSettingsState().graphicsSettings)
      settings.update('sounds', gameSettingsState().volume ? 1 : 0)
      settings.update('qualityAuto', gameSettingsState().graphicsAuto ? 1 : 0)
      settings.update('isLeft', gameSettingsState().isLeft ? 1 : 0)
      settings.update('controlsType', gameSettingsState().controlsType)
      settings.update('aimSensitivity', gameSettingsState().aimSensitivity)
      settings.update('oneFinger', gameSettingsState().oneFinger ? 1 : 0)

    },
    onToggle(isOpen: boolean): void {

      if (isOpen) {

        this.tryLockMenu()

      }
      if (document.body.style.cursor !== 'auto') document.body.style.cursor = 'auto'

      if (this.settingsOpen || instructionsState().showInstructions || this.exitOpen) return
      this.keyOpenMenu = isOpen
      this.$emit('toggle-ui', this.keyOpenMenu)

      if (!isOpen) {

        tutorialState().settings = false

        disciplinePhasesManager.phaseAim.tryToLockPointer()

      }

    },
    tryLockMenu() {

      if (this.isMobile) return

      aimingDirectionManager.makeInitAimingShift(true)
      this.lockMenu = true
      clearTimeout(this.lockTween)

      this.lockTween = window.setTimeout(() => {

        clearTimeout(this.lockTween)
        this.lockMenu = false

      }, 1500)

    }
  }
})

</script>

<style scoped lang="less">
.layout-menu {
    position: absolute;
    top: 1%;
    right: 1%;
    z-index: 1;
}
</style>
