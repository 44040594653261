import {
  type DisciplinePhaseManager,
  PlayerAnimationsNames,
  PlayerStates,
  BlueBoxTextType,
  AudioNames,
  AudioGroups,
  TutorialEventType,
  type CameraIdeals
} from '../../types'
import {
  aimConfig,
  cameraSpecialConfig,
  customCameraConfig,
  debugConfig,
  gameConfig,
  shootingConfig,
  cameraConfig
} from '@/app/config'
import {
  cameraManager,
  CameraStates,
  MobileDetector,
  THREE,
  gsap,
  game,
  playersManager,
  corePhasesManager,
  modes,
  ModeTypes,
  audioManager,
  PlayerSex,
  trainingManager,
  type PlayerInfo,
  inputsManager,
  vibrations
} from '@powerplay/core-minigames'
import { aimingDirectionManager } from './AimingDirectionManager'
import { player } from '@/app/entities/athlete/player'
import { ObjectWrapper } from '@/app/entities/objectWrapper/ObjectWrapper'
import {
  actionButtonState,
  blackOverlayState,
  emotionMessagesState,
  inputsState,
  miniTableState,
  shellsState,
  textMessageState,
  trainingState,
  tutorialState,
  uiState,
  windState
} from '@/stores'
import { startPhaseStateManager } from '../StartPhase/StartPhaseStateManager'
import { pigeon } from '@/app/entities/equipment/Pigeon'
import { timeLimitManager } from '@/app/TimeLimitManager'
import { StateManager } from '@/app/StateManager'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { tutorialUIChange } from '@/app/modes/tutorial/TutorialUIChange'
import { opponentsManager } from '@/app/entities/athlete/opponent/OpponentsManager'
import { trainingTasks } from '@/app/modes/training'
import { worldEnv } from '@/app/entities/env/WorldEnv'

/**
 * Trieda fazy pre mierenie
 */
export class AimPhase implements DisciplinePhaseManager {

  /** Kvalita inputu */
  public quality = 0

  /** wrapper pre pusku a kameru */
  public wrapper = new ObjectWrapper()

  /** kolko krat som vystrelil tento pokus */
  public shotsFired = 0

  /** ci je pripraveny na strelbu */
  public ready = false

  /** ci bol holub zasiahnuty */
  public targetHit = false

  /** Ci uz bol prehraty zvuk miss call */
  public missCallAudioPlayed = false

  /** Ci sme akurat v casti s emociou */
  public isInEmotion = false

  /** Ci je loknute hybanie kamerou, aby neboli prebliky */
  public lockedCameraMove = false

  /** Ci sa ma kamera specialne triast */
  public specialCameraShakeActive = false

  /** Defaultne nastavenia kamery pre shake */
  public shakeDefaults: CameraIdeals = {}

  /** Vektor pre pohyb shake kamery */
  public shakeVector = new THREE.Vector3()

  /** Ci su skipovatelne hlasky pri konecnej emocii */
  public emotionMessagesSkippable = false

  /** Pocitadlo frameov */
  private frameCounter = 0

  /** Ci uz skoncila tato faza */
  private ended = false

  /** Originalna pozicia hraca kvoli resetu */
  private playerPositionOriginal = new THREE.Vector3()

  /** Originalna rotacia hraca kvoli resetu */
  private playerRotationOriginal = new THREE.Euler()

  /** kamera up vektor */
  private CAMERA_UP_VECTOR = new THREE.Vector3(0, 1, 0)

  /** nastavenia pre strielanie holuba */
  private pigeonCanonIndex = 0

  /** ci bol holub vystreleny */
  private released = false

  /** mieridlo */
  private reticle = new THREE.Object3D()

  /** hit marker pre mieridlo */
  private reticleHitMarker = new THREE.Object3D()

  /** mieridlo */
  private rectangleMesh = new THREE.LineSegments()

  /** smery ktorymi sa vystrelia holuby */
  private pigeonDirections: number[] = []

  /** raycast strely */
  private shotRaycast = new THREE.Raycaster()

  /** Kde bol holub zasiahnuty */
  private pigeonHitPosition = new THREE.Vector3()

  /** broky */
  private shotBalls = new THREE.Group()

  /** kam sa ma pozerat kamera (pouziva sa hlavne pri mobilnom ovladani) */
  private targetToLookAt = new THREE.Vector3()

  /** world pozicia reticle */
  private reticleWorldPos = new THREE.Vector3()

  /** hracov bod kam sa ma pozerat */
  private playerTargetToLookAt = new THREE.Vector3()

  /** hracov bod kam sa ma pozerat */
  private tempVectorForPlayerTarget = new THREE.Vector3()

  /** ci sme tento pokus uz zobrazili minitable */
  private minitableShown = false

  /** tween pre unlock skipu finalnej emocie */
  private tweenToSkipFinalEmotions?: gsap.core.Tween

  /** tween na animaciu reticle */
  private reticleTween?: gsap.core.Tween

  /** tween pre timeline animaciu */
  private reticleTweenTimeline?: gsap.core.Timeline

  /** ci sa podarilo zamknut kurzor */
  public isLocked = false

  /** ci ma byt locknute */
  private shouldBeLocked = false

  /** ci uz sme zmenili skore opponentom po nepodarenom pokuse */
  private opponentsScoreChanged = false

  /** Helper quat */
  private quat = new THREE.Quaternion()

  /** ci bezi replay */
  public isReplay = false

  /** Pozicia pre nastavenie kamery */
  private pos = new THREE.Vector3()

  /** pozicia na mobil */
  private posMobile = new THREE.Vector3()

  /** pozicia na quaternion camery */
  private quatMobile = new THREE.Quaternion()

  /** Helper pre poziciu kamery */
  private cameraPosHelper = new THREE.Vector3()

  /** Helper pre poziciu gulicky */
  private ballPosHelper = new THREE.Vector3()

  /** ci sa ma resetovat kamera pred dalsim posunutim kamery */
  public changeCameraToReplayNextCameraMove = false

  /** data pre replay */
  private pigeonReleaseData = {
    pos: new THREE.Vector3(),
    angleX: 0,
    angleY: 0,
    speed: 0
  }
  /** Kolko emocnych messageov budeme zobrazovat */
  private emotionMessagesCount = 0

  /** tween na oddialeny finish fazy */
  private delayFinishTween?: gsap.core.Tween

  /** tween na unloading */
  private unloadingTween?: gsap.core.Tween

  /** tween na emocii */
  private emotionTween?: gsap.core.Tween

  /** helper pre hsap circle scale */
  private circleScaleHelper = new THREE.Vector3()

  /** kolko trva jedna emocia v sekundach */
  private readonly ONE_EMOTION_IN_SECONDS = 1.5 // durations translation + delayToHide + hide

  /**
   * Konstruktor
   * @param callbackEnd - callback na zavolanie po skonceni fazy
   */
  public constructor(private callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase = (): void => {

    // zatial netreba nic

  }

  /**
   * Start fazy
   */
  public startPhase = (): void => {

    this.shouldBeLocked = true

    if (!(modes.isTutorial() && tutorialFlow.firstAttempt)) {

      this.tryToLockPointer()

    }

    player.setPositionOnAttemptStart()
    opponentsManager.setPositionOnAttemptStart()
    actionButtonState().showJoystick = true
    startPhaseStateManager.enableInputs()
    console.warn('aim phase started')
    aimingDirectionManager.setMouseStep()
    aimingDirectionManager.createAimingPoint()
    this.setCameraWrapper()
    this.setCameraRendering()


    if (MobileDetector.isMobile()) {

      this.rectangleMesh.getWorldPosition(this.pos)

    }
    aimingDirectionManager.makeInitAimingShift(true, this.pos)
    this.wrapper.lookAt(aimingDirectionManager.aimingPoint.position)

    aimingDirectionManager.aimingPoint.visible = aimConfig.debug.showAimingPoint
    aimingDirectionManager.aimingPoint.visible = aimConfig.debug.showAimingPoint

    this.pigeonCanonIndex = (corePhasesManager.disciplineActualAttempt - 1) % 5
    if ([1, 6].includes(corePhasesManager.disciplineActualAttempt)) {

      this.shufflePigeonDirections()

    }

    this.lockedCameraMove = false

    blackOverlayState().showBlackScreen = false
    if (modes.isTutorial() && tutorialFlow.firstAttempt) {

      tutorialFlow.init()
      tutorialUIChange.init()

    }

  }

  /**
   * Pokusime sa locknut kurzor
   */
  public tryToLockPointer(): void {

    if (MobileDetector.isMobile() || !this.shouldBeLocked || this.isLocked || debugConfig.debugCamera) return

    console.log('try lock')

    const canvasEl = game.renderManager.getDomElement()
    if (!canvasEl.requestPointerLock) return

    document.addEventListener('pointerlockerror', this.lockError, false)

    canvasEl.requestPointerLock()
    aimingDirectionManager.update()

    document.addEventListener('pointerlockchange', this.lockChange.bind(this), false)

  }

  /**
   * Event pri zmene pointerlock
   */
  private lockChange(): void {

    const canvasEl = game.renderManager.getDomElement()
    if (document.pointerLockElement === canvasEl) {

      console.log('The pointer lock status is now locked')
      this.isLocked = true
      uiState().showEsc = true
      inputsState().exitPressed = false

    } else {

      console.log('The pointer lock status is now unlocked')
      this.isLocked = false
      uiState().showEsc = false
      if (this.shouldBeLocked) {

        inputsState().exitPressed = true

      }
      document.removeEventListener('pointerlockerror', this.lockError, false)
      document.removeEventListener('pointerlockchange', this.lockChange.bind(this), false)

    }

  }

  /**
   * Odomknutie pointera
   * @param openMenu - ci sa ma otvorit menu
   */
  public unlockPointer(openMenu = false): void {

    if (MobileDetector.isMobile() || !this.isLocked || !document.exitPointerLock) return

    console.log('unlock')
    this.shouldBeLocked = openMenu
    document.exitPointerLock()
    this.isLocked = false

  }

  /**
   * Riesenie erroru pri lockovani
   */
  private lockError(): void {

    console.log('Pointer sa nepodarilo locknut')
    this.isLocked = false

  }

  /**
   * Nastavovanie viditelnosti mieridla
   * @param visible - value
   */
  public setReticleVisible(visible: boolean) {

    this.reticle.visible = visible

  }


  /**
   * zamiesanie smerov vystrelenia holubov
   */
  private shufflePigeonDirections(): void {

    this.pigeonDirections = [0, 0, 1, 2, 2]
    let currentIndex = this.pigeonDirections.length
    let randomIndex = this.pigeonDirections.length

    // While there remain elements to shuffle.
    while (currentIndex != 0) {

      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex--;

      // And swap it with the current element.
      [
        this.pigeonDirections[currentIndex],
        this.pigeonDirections[randomIndex]
      ] = [
        this.pigeonDirections[randomIndex],
        this.pigeonDirections[currentIndex]
      ]

    }

  }

  /**
   * vytvorenie obdlznika na mierenie
   */
  private createAimRectangle(): void {

    const rectangleMeshName = 'rectangleMesh'
    if (this.rectangleMesh.name === rectangleMeshName) return

    const { aimRectangle } = aimConfig
    const geometry = new THREE.BoxGeometry( aimRectangle.x, aimRectangle.y, 0 )
    const edges = new THREE.EdgesGeometry( geometry )
    const line = new THREE.LineSegments(edges, new THREE.LineBasicMaterial( { color: 0xffffff } ) )
    this.rectangleMesh = line
    this.rectangleMesh.name = rectangleMeshName
    this.rectangleMesh.visible = shootingConfig.debugShot

    game.scene.add( this.rectangleMesh )


  }

  /**
   * vytvorenie kruhu na mierenie
   */
  private createAimCircle(): void {

    const { aimCircle } = aimConfig

    this.reticle = game.getObject3D('Trap_Reticle_Mesh004')
    this.reticle.matrixAutoUpdate = true
    this.reticle.visible = false
    this.reticle.scale.set(aimCircle.diameter, aimCircle.diameter, aimCircle.diameter)

    this.reticleHitMarker = game.getObject3D('envDynamic_Trap_HitMarker')
    this.reticleHitMarker.matrixAutoUpdate = true
    this.reticleHitMarker.visible = false
    this.reticleHitMarker.scale.set(aimCircle.diameter, aimCircle.diameter, aimCircle.diameter)

  }
  /**
   * vytvorenie bodov pre raycast brokov
   */
  private createShotBalls(): void {

    const { offsetX, offsetY, deviations } = shootingConfig.raycastOffsets

    const { aimCircleOffset } = aimConfig.camera
    deviations.forEach((deviation, index) => {

      const name = `shotBall-${index}`
      let meshSphereObject = game.scene.getObjectByName(name)
      if (meshSphereObject === undefined) {

        const geometrySphere = new THREE.SphereGeometry(0.01, 1, 1)
        const materialSphere = new THREE.MeshBasicMaterial({ color: Math.random() * 0xffffff })
        const meshSphere = new THREE.Mesh(geometrySphere, materialSphere)
        meshSphere.visible = shootingConfig.debugShot
        meshSphere.name = name
        this.shotBalls.add(meshSphere)
        meshSphereObject = meshSphere

      }
      meshSphereObject.position.set(
        aimCircleOffset.x + deviation.x * offsetX,
        aimCircleOffset.y + deviation.y * offsetY,
        0,
      )

    })
    this.shotBalls.position.set(aimCircleOffset.x, aimCircleOffset.y, aimCircleOffset.z)
    game.scene.add(this.shotBalls)

  }

  /**
   * dame kameru a pusku do wrappera
   */
  public setCameraWrapper(): void {

    const { cameraAim } = cameraSpecialConfig
    const camera = cameraManager.getMainCamera()
    camera.up.set(0, 1, 0)
    this.createAimCircle()
    this.createAimRectangle()
    this.createShotBalls()
    cameraManager.setState(CameraStates.static)

    const wrapperPosition = player.athleteObject.position.clone()

    const { aimCircleOffset, wrapperPositionOffset } = aimConfig.camera
    this.reticle.position.set(aimCircleOffset.x, aimCircleOffset.y, aimCircleOffset.z)
    this.reticleHitMarker.position.set(aimCircleOffset.x, aimCircleOffset.y, aimCircleOffset.z)
    this.rectangleMesh.position.set(aimCircleOffset.x, aimCircleOffset.y, aimCircleOffset.z)
    camera.position.set(cameraAim.idealOffset.x, cameraAim.idealOffset.y, cameraAim.idealOffset.z)
    camera.lookAt(cameraAim.idealLookAt)
    wrapperPosition.add(wrapperPositionOffset)

    if (MobileDetector.isMobile()) {

      this.wrapper.set(
        wrapperPosition,
        camera,
        this.rectangleMesh
      )

    } else {

      this.wrapper.set(
        wrapperPosition,
        camera,
        this.reticle,
        this.reticleHitMarker,
        this.rectangleMesh
      )

    }

    // musime si poznacit, aka bola pozicia a rotacia
    this.playerPositionOriginal = player.athleteObject.position.clone()
    this.playerRotationOriginal = player.athleteObject.rotation.clone()

    // hraca este musime kusocek posunut
    player.athleteObject.position.x += aimConfig.shiftPlayerOnX

  }

  /**
   * nastavime vykreslovanie kamery
   */
  public setCameraRendering(): void {

    player.changeCameraRenderSettings(
      aimConfig.camera.near,
      aimConfig.camera.far,
      MobileDetector.isMobile() ? aimConfig.camera.fovMobile : aimConfig.camera.fovDesktop
    )

    cameraManager.getMainCamera().up = this.CAMERA_UP_VECTOR

  }

  /**
   * Natocenie hraca podla kamery
   */
  private rotatePlayerAccordingToCamera(): void {

    this.tempVectorForPlayerTarget = cameraManager.getMainCamera().position.clone().sub(this.targetToLookAt).negate()
    this.tempVectorForPlayerTarget.x *= aimConfig.rotationPercentPlayer.horizontal
    this.tempVectorForPlayerTarget.y *= aimConfig.rotationPercentPlayer.vertical
    this.playerTargetToLookAt = cameraManager.getMainCamera().position.clone().add(this.tempVectorForPlayerTarget)

    player.athleteObject.lookAt(this.playerTargetToLookAt)

  }

  /**
   * Shake kamery pri emociach
   */
  private cameraShakeEmotions(): void {

    const { chanceInFrame, active } = cameraConfig.shakeEmotions

    if (!active || !this.specialCameraShakeActive || Math.random() > chanceInFrame) return

    let newDirShakeCamera = 0
    const maxDir = Math.PI * 2
    const { power, maxValueVector } = cameraConfig.shakeEmotions

    newDirShakeCamera = THREE.MathUtils.randFloat(0, maxDir)

    const yShift = Math.sin(newDirShakeCamera) * power
    const xShift = Math.cos(newDirShakeCamera) * power

    this.shakeVector.y += yShift
    if (this.shakeVector.y > maxValueVector) this.shakeVector.y = maxValueVector
    if (this.shakeVector.y < -maxValueVector) this.shakeVector.y = -maxValueVector
    this.shakeVector.x += xShift
    if (this.shakeVector.x > maxValueVector) this.shakeVector.x = maxValueVector
    if (this.shakeVector.x < -maxValueVector) this.shakeVector.x = -maxValueVector

    player.changeCameraSettings(
      this.shakeDefaults.idealOffset,
      this.shakeDefaults.idealLookAt?.clone().add(this.shakeVector),
      this.shakeDefaults.coefSize,
      this.shakeDefaults.changeLerp,
      this.shakeDefaults.staticMovement,
      true
    )

  }

  /**
   * Aktualizovanie fazy
   */
  public update = (): void => {

    this.frameCounter += 1
    this.targetToLookAt = aimingDirectionManager.aimingPoint.position
    this.updateAimMobile()
    // schovame kurzor
    if (
      document.body.style.cursor !== 'none' &&
      !(modes.isTutorial() &&
      tutorialFlow.cursorVisible) &&
      !this.isInEmotion &&
      !debugConfig.debugCamera
    ) {

      document.body.style.cursor = 'none'
      console.log('cursor prec')

    }

    if (!this.ended && !this.isInEmotion && !this.isReplay) {

      this.wrapper.lookAt(this.targetToLookAt)
      this.rotatePlayerAccordingToCamera()

      aimingDirectionManager.update()

    }

    this.reticle.getWorldPosition(this.reticleWorldPos)
    this.shotBalls.position.set(this.reticleWorldPos.x, this.reticleWorldPos.y, this.reticleWorldPos.z)
    this.shotBalls.lookAt(this.targetToLookAt)

    this.cameraShakeEmotions()

  }

  /**
   * Aktualizovanie pozicii mierenia pre mobilne ovladanie
   */
  public updateAimMobile(): void {

    if (!MobileDetector.isMobile()) return
    if (pigeon.pigeonFlying) {

      this.targetToLookAt = pigeon.pigeonBody.position

    }
    if (this.targetHit) this.targetToLookAt = this.pigeonHitPosition

    this.rectangleMesh.getWorldQuaternion(this.quatMobile)
    this.rectangleMesh.getWorldPosition(this.posMobile)

    this.setAimingPointPosition(this.posMobile, this.quatMobile)

    this.reticle.position.set(
      aimingDirectionManager.aimingPoint.position.x,
      aimingDirectionManager.aimingPoint.position.y,
      this.posMobile.z
    )
    this.reticle.lookAt(this.targetToLookAt)

    this.reticleHitMarker.position.set(
      aimingDirectionManager.aimingPoint.position.x,
      aimingDirectionManager.aimingPoint.position.y,
      this.posMobile.z
    )
    this.reticleHitMarker.lookAt(this.targetToLookAt)

  }

  /**
   * Nastavenie pozicie aimingPoint
   * @param pos - world pozicia
   * @param quat - world quat
   */
  private setAimingPointPosition(pos: THREE.Vector3, quat: THREE.Quaternion): void {

    let rectangleXWidth = aimConfig.aimRectangle.x / 2
    const rectangleHeight = aimConfig.aimRectangle.y / 2

    const angleX = quat.angleTo(this.quat)
    rectangleXWidth = Math.cos(angleX) * rectangleXWidth


    const { aimCircle } = aimConfig
    if (aimingDirectionManager.aimingPoint.position.x + aimCircle.diameter > (pos.x + rectangleXWidth)) {

      aimingDirectionManager.aimingPoint.position.x = pos.x + rectangleXWidth - aimCircle.diameter

    }
    if (aimingDirectionManager.aimingPoint.position.x - aimCircle.diameter < (pos.x - rectangleXWidth)) {

      aimingDirectionManager.aimingPoint.position.x = pos.x - rectangleXWidth + aimCircle.diameter

    }
    if (aimingDirectionManager.aimingPoint.position.y + aimCircle.diameter > (pos.y + rectangleHeight)) {

      aimingDirectionManager.aimingPoint.position.y = pos.y + rectangleHeight - aimCircle.diameter

    }
    if (aimingDirectionManager.aimingPoint.position.y - aimCircle.diameter < (pos.y - rectangleHeight)) {

      aimingDirectionManager.aimingPoint.position.y = pos.y - rectangleHeight + aimCircle.diameter

    }

  }

  /**
   * Spravenie veci po tom, co je hrac pripraveny
   */
  public afterPrepared(): void {

    if (modes.isTutorial() && !tutorialFlow.wasAfterLoading) {

      tutorialFlow.eventActionTrigger(TutorialEventType.afterLoading)

    } else {

      this.setReady(true)

    }
    this.setReticleVisible(true)
    timeLimitManager.setActive(true)
    windState().showWind = modes.isTutorial() ? !tutorialFlow.firstAttempt : true
    uiState().blueBoxTextType = BlueBoxTextType.callTarget

  }

  /**
   * Resetovanie veci s kamerou, wrapperom a pod
   */
  public resetCameraAndWrapper(): void {

    if (this.wrapper) this.wrapper.destroy()

    this.wrapper = new ObjectWrapper()

    player.athleteObject.position.set(
      this.playerPositionOriginal.x,
      this.playerPositionOriginal.y,
      this.playerPositionOriginal.z
    )

    player.athleteObject.rotation.set(
      this.playerRotationOriginal.x,
      this.playerRotationOriginal.y,
      this.playerRotationOriginal.z
    )

    // vratime naspat kurzor mysy
    document.body.style.cursor = 'auto'

  }

  /**
   * Vypustenie holuba po delayi
   */
  private releasePigeonAfterDelay(): void {

    const { angleX, angleY, startPositions } = shootingConfig.pigeon

    let index = this.pigeonDirections[this.pigeonCanonIndex]
    if (index === undefined || ![0, 1, 2].includes(index)) index = 0

    const angleXRand = THREE.MathUtils.randFloat(
      angleX[index].min,
      angleX[index].max
    )
    const angleYRand = THREE.MathUtils.randFloat(
      angleY.min,
      angleY.max
    )

    let minSpeed = shootingConfig.pigeon.speed.min
    let maxSpeed = shootingConfig.pigeon.speed.max

    const strength = playersManager.getPlayerById(player.uuid)?.attribute.total || 0
    if (strength < 100) {

      minSpeed = 21 + 6 * strength / 100
      maxSpeed = 24 + 6 * strength / 100

    }

    const speed = THREE.MathUtils.randFloat(minSpeed, maxSpeed)

    this.pigeonReleaseData = {
      pos: startPositions[this.pigeonCanonIndex],
      angleX: angleXRand,
      angleY: angleYRand,
      speed
    }

    pigeon.releasePigeon(
      startPositions[this.pigeonCanonIndex],
      angleXRand,
      angleYRand,
      speed
    )

    console.log('Smer letu', this.pigeonDirections[this.pigeonCanonIndex])
    console.log('minSpeed:', minSpeed, 'maxSpeed:', maxSpeed, 'vygenerovana rychlost:', speed)

  }

  /**
   * Vypustenie holuba
   */
  public releasePigeon(): void {

    uiState().blueBoxTextType = BlueBoxTextType.hidden
    if (this.released || !this.ready) return

    const playerSex = playersManager.getPlayer().sex
    audioManager.play(playerSex === PlayerSex.male ? AudioNames.targetCallMan : AudioNames.targetCallWoman)

    actionButtonState().disabled = false
    timeLimitManager.setActive(false)
    this.released = true
    tutorialFlow.firstAttemptTimer?.kill()
    tutorialState().showRelease = false

    gsap.to({}, {
      duration: aimConfig.pigeonReleaseDelay,
      onComplete: () => {

        this.releasePigeonAfterDelay()

      }
    })

  }

  /**
   * Vratenie nahodnej happy emocnej animacie
   * @returns Animacia
   */
  private getRandomHappyAnimation(): PlayerAnimationsNames {

    const randomHappy = THREE.MathUtils.randInt(1, 3)
    const emotionObject = {
      1: PlayerAnimationsNames.happy1,
      2: PlayerAnimationsNames.happy2,
      3: PlayerAnimationsNames.happy3,
    }

    return emotionObject[randomHappy as keyof typeof emotionObject]

  }

  /**
   * Vratenie finalnej emocie po poslednom vystrele pri emocnej kamere
   * @returns Animacia
   */
  private getFinalEmotion(): PlayerAnimationsNames {

    let emotion = PlayerAnimationsNames.afterEmotion

    if (modes.isTrainingMode()) {

      // trening
      const tasks = trainingManager.getTrainingTasks()
      const sum = tasks.reduce((prev, current) => prev + current.value, 0)
      const average = sum / tasks.length

      // ak 3 hviezdy
      if (average > 0.9) emotion = this.getRandomHappyAnimation()

    } else {

      // zapas, bossfight a pod
      const bossFight = modes.isEventBossFight()
      const position = playersManager.getPlayerActualPosition()
      const maxPositionToWin = bossFight ? 1 : 3

      if (position <= maxPositionToWin) {

        // happy
        emotion = this.getRandomHappyAnimation()

      } else if (position > 5 || bossFight) {

        // sad
        emotion = PlayerAnimationsNames.sad

      }

    }

    return emotion

  }

  /**
   * Vratenie emocie hraca po strelbe
   * @returns Emocia
   */
  public getEmotion(): PlayerAnimationsNames {

    let emotion = PlayerAnimationsNames.fistPump

    if (corePhasesManager.disciplineActualAttempt >= corePhasesManager.disciplineAttemptsCount) {

      emotion = this.getFinalEmotion()

    } else if (!this.targetHit) {

      emotion = PlayerAnimationsNames.sad

    }

    return emotion

  }

  /**
   * Nastavenie timeru na skip final emocie
   */
  private setTimerToSkipFinalEmotions(): void {

    // dame timer na skip poslednej emocie s hlaskami
    this.tweenToSkipFinalEmotions = gsap.to({}, {
      duration: 1.5,
      onComplete: () => {

        this.emotionMessagesSkippable = true

      }
    })

  }

  /**
   * nastavime emocne message
   * @returns True, ak zobrazujeme emocnu hlasku
   */
  private showEmotionMessages(): boolean {

    const points = playersManager.getCountedResultsMainSoFar(playersManager.getPlayer().uuid)
    const personalBest = playersManager.getPlayer().personalBest
    const position = playersManager.getPlayerActualPosition()

    const inTop3 = position <= 3
    const isPersonalBest = points >= personalBest

    // ak mame nejaku hlasku/y, tak zobrazime kameru kusok inak
    if ((modes.isDailyLeague() && !playersManager.isPlayerImproved()) || (!inTop3 && !isPersonalBest)) return false

    startPhaseStateManager.hideAllTextMessages()

    let pbText = ''
    if (isPersonalBest) {

      pbText = points > personalBest ? 'newPersonalBest' : 'personalBest'

    }

    emotionMessagesState().$patch({
      showMessage: true,
      firstLineText: inTop3 ? 'congratulations' : '',
      firstLineTextSecond: pbText,
      secondLineText: inTop3 ? position : 0,
      secondLineTextSecond: isPersonalBest ? String(points) : '',
    })

    // dame timer na skip poslednej emocie s hlaskami
    this.setTimerToSkipFinalEmotions()

    return true

  }

  /**
   * nastavime data pre top box
   */
  public prepareEmotionMessages(): void {

    let withEmotionMessages = false

    // UI pri emocii, ak islo o posledny pokus
    if (modes.isTrainingMode()) {

      if (!trainingTasks.nextAttemptAsRepeat) {

        trainingState().showLeftBoxes = false

        // HIGH SCORE - TRENING
        trainingState().$patch({
          newHighScore: Math.ceil(trainingManager.getNewPotentialHighScore()),
          showNewHighScore: trainingManager.isNewHighScore()
        })

        this.setTimerToSkipFinalEmotions()

      }

    } else {

      // UI veci mimo TG
      withEmotionMessages = this.showEmotionMessages()

    }

    // uz nechceme mat zobrazene buttony ani web infa
    actionButtonState().$patch({
      showJoystick: false,
      isShoot: false
    })

    if (withEmotionMessages) return

    let waitInEmotion = shootingConfig.waitInEmotion
    const newPossibleWaitInEmotion = (this.emotionMessagesCount * this.ONE_EMOTION_IN_SECONDS) + 1
    if (newPossibleWaitInEmotion > waitInEmotion) waitInEmotion = newPossibleWaitInEmotion

    this.emotionTween = gsap.to(
      {},
      {
        duration: waitInEmotion,
        onComplete: () => {

          this.tweenToSkipFinalEmotions?.kill()
          this.afterEmotions()

        }
      }
    )

  }

  /**
   * Spravenie veci po emociach
   */
  public afterEmotions(): void {

    trainingState().showNewHighScore = false
    emotionMessagesState().showMessage = false

    this.setFinishPhaseTween()

  }

  /**
   * Kontrola a riesenie inputov
   * @param isTouch - ci input je touch na mobile
   * @param onlyRelease - ci volame iba release
   * @param onlyShoot - ci volame iba strelbu
   */
  public handleInputs(isTouch = false, onlyRelease = false, onlyShoot = false): void {

    if (onlyRelease && this.released) return
    if (onlyShoot && !this.released) return

    // skip na emocie
    if (this.emotionMessagesSkippable) {

      this.emotionTween?.kill()
      this.emotionMessagesSkippable = false
      this.afterEmotions()

      return

    }

    if (
      this.isReplay ||
      (
        this.isInEmotion &&
        (
          corePhasesManager.disciplineActualAttempt !== corePhasesManager.disciplineAttemptsCount ||
          trainingTasks.nextAttemptAsRepeat
        )
      )
    )
    {

      console.log('SKIP')
      pigeon.pigeonFlying = false
      this.changeCameraToReplayNextCameraMove = false
      this.targetHit = true
      shellsState().$patch({
        shellsLeft: 0,
        showShells: false
      })
      actionButtonState().$patch({
        showJoystick: false,
        isShoot: false
      })

      this.finishPhase()
      inputsManager.handleMouseUp()
      return

    }

    if ((MobileDetector.isMobile() && !isTouch) || !this.ready) return
    console.log('CLICK')


    if (this.released) {

      this.shoot()
      return

    }

    // vypustime holuba
    this.releasePigeon()
    actionButtonState().isShoot = true

  }

  /**
   * Prehratie komentatora po poslednom vystrele
   * @returns audioName
   */
  private playAudioCommentatorRank(): string {

    if (audioManager.isAudioGroupPlaying(AudioGroups.commentators)) return ''

    const rank = playersManager.getPlayerActualPosition()

    let audio = AudioNames.commentatorRank6

    if (rank === 1) {

      audio = AudioNames.commentatorRank1

    } else if (rank <= 3) {

      audio = AudioNames.commentatorRank23

    } else if (rank <= (modes.isDailyLeague() || modes.isBossCompetition() ? 10 : 5)) {

      audio = AudioNames.commentatorRank45

    }

    // pri boss fighte a 2. mieste davame speci hlasku
    if (modes.isEventBossFight() && rank > 1) audio = AudioNames.commentatorRank6

    return audio

  }

  /**
   * Prehratie zvukov po strielani
   * @param hit - ci bol trafeny terc alebo nie
   */
  public playAudioAfterShooting(hit = true): void {

    let commentatorAudioName = ''

    // pri poslendom pokuse uz zaciname hrat hyped
    if (corePhasesManager.disciplineActualAttempt === gameConfig.numberOfAttempts) {

      commentatorAudioName = this.playAudioCommentatorRank()

    } else {

      commentatorAudioName = AudioNames.commentatorMiss
      if (hit) {

        commentatorAudioName = this.shotsFired === 1 ?
          AudioNames.commentatorHitOneShot :
          AudioNames.commentatorHitTwoShot

      }

    }

    let audioToPlayWithDelay = ''

    if (!this.missCallAudioPlayed) {

      audioToPlayWithDelay = AudioNames.targetMissCall
      this.missCallAudioPlayed = true

    }

    if (hit) {

      audioToPlayWithDelay = AudioNames.clap
      if (this.shotsFired === 1) audioToPlayWithDelay = AudioNames.cheerClap
      audioManager.play(AudioNames.targetHit)

    }

    gsap.to({}, {
      duration: 0.5,
      onComplete: () => {

        audioManager.play(audioToPlayWithDelay)

        if (commentatorAudioName !== '' && !audioManager.isAudioGroupPlaying(AudioGroups.commentators)) {

          audioManager.play(commentatorAudioName, undefined, undefined, undefined, true)

        }

      }
    })

  }

  /**
   * Nastavenie unloadingu pusky
   */
  public setUnloading(): void {

    // ked ide o druhe kolo a netrafime, tak uz nechceme, aby sa dalej znizoval holub
    if (!this.targetHit && corePhasesManager.disciplineActualAttempt > corePhasesManager.provisionalResultsFrequency) {

      pigeon.stopReducingScale()

    }

    this.unloadingTween = gsap.to({}, {
      onComplete: () => {

        player.setState(PlayerStates.unloading)

      },
      duration: 0.1
    })


  }

  /**
   * Vystrelenie
   */
  private shoot(): void {

    if (
      this.shotsFired >= shootingConfig.shotsLimit ||
      this.targetHit ||
      pigeon.hitGround ||
      this.isReplay ||
      this.isInEmotion
    ) return

    console.log('SHOT')
    if (this.shotsFired === 1) audioManager.stopAudioByName(AudioNames.gunShot)
    audioManager.play(AudioNames.gunShot)
    this.shotsFired += 1

    shellsState().$patch({
      shellsLeft: 2 - this.shotsFired,
      showShells: true
    })
    this.reticleAnimation()
    player.setState(PlayerStates.shoot)
    player.gun.showEffectsAfterShot()

    this.shotBalls.children.forEach(shotBall => {

      if (this.targetHit) return

      cameraManager.getMainCamera().getWorldPosition(this.cameraPosHelper)

      const raycastDir = aimingDirectionManager.aimingPoint.position.clone().sub(this.cameraPosHelper)

      this.shotRaycast.set(
        this.cameraPosHelper,
        raycastDir
      )

      shotBall.getWorldPosition(this.ballPosHelper)
      this.shotRaycast.ray.lookAt(this.ballPosHelper)
      if (this.shotRaycast.intersectObject(pigeon.pigeonBody).length) {

        this.targetHit = true
        console.log('Pigeon hit')
        vibrations.vibrate()

        this.pigeonHitPosition = pigeon.pigeonBody.position.clone()
        pigeon.pigeonHit()
        startPhaseStateManager.hideButtons()

      }

      if (shootingConfig.debugShot) {

        const debugArrow = new THREE.ArrowHelper(
          this.shotRaycast.ray.direction,
          this.shotRaycast.ray.origin,
          1,
          Math.random() * 0xffffff,
          undefined,
          0.01
        )
        game.scene.add(debugArrow)

      }

    })

    this.reticleHitMarkerAnimation()

    const shotPoints = this.getShotPoints()

    if (this.targetHit) {

      playersManager.setPlayerResults(shotPoints)
      playersManager.setStandings()

    }

    if (this.shotsFired >= shootingConfig.shotsLimit && !pigeon.hitGround) {

      startPhaseStateManager.hideButtons()
      pigeon.tooManyMisses = true
      this.setUnloading()

      if (trainingTasks.attempts[corePhasesManager.disciplineActualAttempt] === 0) {

        trainingTasks.nextAttemptAsRepeat = true

      }

    }

    if (!this.targetHit && this.shotsFired < shootingConfig.shotsLimit) return


    if (this.targetHit) {

      trainingTasks.countShotPoints(shotPoints)
      this.playAudioAfterShooting(true)

    } else {

      this.playAudioAfterShooting(false)

    }

    if (this.isReplay) return

    this.showMiniTable()
    this.adjustAttemptScoreForAll()

  }

  /**
   * Vypocita kolko bodov sme ziskali
   * @returns shotPoints
   */
  private getShotPoints(): number {

    const { first, second } = shootingConfig.shotPoints
    return this.targetHit ? this.shotsFired === 1 ? first : second : 0

  }

  /**
   * Vyberie typ hlasky pre prvy riadok
   * @returns firstLineTextType
   */
  private getFirstLineTextType(): number {

    let firstLineTextType = 0
    if (this.targetHit) {

      if (this.shotsFired === 1) {

        firstLineTextType = THREE.MathUtils.randInt(1, 4)

      } else {

        firstLineTextType = THREE.MathUtils.randInt(5, 7)

      }

    }
    return firstLineTextType

  }

  /**
   * Schovanie mieritka spolu so zobrazenim hit markeru
   */
  private hideReticleWithHitMarker(): void {

    // ked ukazujeme hit marker, tak reticle schovavame az neskor
    this.reticleHitMarker.visible = true

    this.reticleTweenTimeline?.kill()
    this.reticleTweenTimeline = gsap.timeline()
      .to({}, {
        duration: 0.15,
        onComplete: () => {

          this.reticleHitMarker.visible = false

        }
      })
      .to({}, {
        duration: 0.15,
        onComplete: () => {

          this.setReticleVisible(false)

        }
      })

  }

  /**
   * Animacia hit markeru mieritka
   */
  private reticleHitMarkerAnimation(): void {

    if (this.targetHit) this.hideReticleWithHitMarker()

  }

  /**
   * Animacia mieridla
   */
  private reticleAnimation(): void {

    const { diameter, duration, biggerDiameter } = aimConfig.aimCircle
    this.circleScaleHelper.set(diameter, diameter, diameter)

    this.reticleTween?.kill()
    this.reticleTween = gsap.to(this.circleScaleHelper, {
      onUpdate: () => {

        // console.log(circleScale)
        this.reticle.scale.set(this.circleScaleHelper.x, this.circleScaleHelper.y, this.circleScaleHelper.z)

      },
      onComplete: () => {

        if (!this.targetHit && this.shotsFired >= shootingConfig.shotsLimit) this.setReticleVisible(false)

      },
      x: biggerDiameter,
      y: biggerDiameter,
      z: biggerDiameter,
      ease: 'circ.inOut',
      yoyo: true,
      repeat: 1,
      duration
    })

  }

  /**
   * Zobrazenie minitablky
   */
  public showMiniTable(delay = 1): void {

    if (this.minitableShown) return

    const firstLineTextType = this.getFirstLineTextType()
    const shotPoints = this.getShotPoints()
    const showFirstLine = true
    const showSecondLine = !modes.isTrainingMode() && this.targetHit
    const showThirdLine = false

    startPhaseStateManager.resetTextMessageFinishedEmits(showFirstLine, showSecondLine, showThirdLine)
    gsap.to({}, {
      duration: 0.01,
      onComplete: () => {

        textMessageState().$patch({
          showFirstLine: showFirstLine,
          showSecondLine: showSecondLine,
          firstLineText: 'text-trap-sprite',
          firstLineTextType,
          secondLineTextType: 0,
          secondLineLeftNumber: shotPoints,
          showMessage: true,
          showType: !modes.isTrainingMode() && this.targetHit ? 2 : 3
        })

      }
    })

    if (
      modes.isTrainingMode() ||
      modes.isTutorial() ||
      corePhasesManager.disciplineActualAttempt % corePhasesManager.provisionalResultsFrequency === 0
    ) return

    gsap.to({}, {
      onComplete: () => {

        playersManager.setStandings()
        this.minitableShown = true
        miniTableState().$patch({
          rowsData: StateManager.getDataForMiniTable(shotPoints),
          show: true
        })

      },
      duration: delay
    })

  }

  /**
   * Upravi score protihracov pre aktualny pokus, ak treba
   */
  public adjustAttemptScoreForAll(): void {

    const excludeMode = modes.isTrainingMode() || modes.isDailyLeague() || modes.isModeByParam(ModeTypes.tournament)
    if (excludeMode || this.opponentsScoreChanged || this.targetHit) return

    this.opponentsScoreChanged = true
    console.log('upravujem score pre protihracov pre pokus', corePhasesManager.disciplineActualAttempt)
    playersManager.players.filter((playerInfo: PlayerInfo) => {

      return !playerInfo.playable

    }).forEach(opponentInfo => {

      console.log(`Stare score opponenta ${opponentInfo.uuid} je ${
        opponentInfo?.resultsArr?.[corePhasesManager.disciplineActualAttempt - 1].main
      }`)
      if (
        opponentInfo &&
        opponentInfo.resultsArr &&
         (opponentInfo.resultsArr[corePhasesManager.disciplineActualAttempt - 1].main || 0) > 0
      ) {

        opponentInfo.resultsArr[corePhasesManager.disciplineActualAttempt - 1].main =
          this.calculateNewScore(opponentInfo)
        console.log(`Nove score opponenta ${opponentInfo.uuid} je ${
          opponentInfo.resultsArr[corePhasesManager.disciplineActualAttempt - 1].main
        }`)

      }

    })

  }

  /**
   * Vypocitanie noveho score pre protihraca
   * @param opponentInfo - PlayerInfo protihraca
   * @returns score
   */
  private calculateNewScore(opponentInfo: PlayerInfo): number {

    const strengthDiff = opponentInfo.attribute.total - playersManager.getPlayer().attribute.total
    let hitProbability = 50 + strengthDiff / 2
    hitProbability = Math.min(hitProbability, 100)
    hitProbability = Math.max(hitProbability, 0)

    if (Math.random() * 100 < hitProbability) return 10
    if (Math.random() * 100 < hitProbability) return 9

    return 0

  }


  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    this.emotionMessagesSkippable = false

    // vyhodnotime body
    playersManager.setStandings()
    windState().showWind = false

    shellsState().$patch({
      shellsLeft: 0,
      showShells: false
    })
    // koniec, pockame este chvilku
    this.delayFinishTween = gsap.to(
      {},
      {
        duration: this.isReplay ? 2 : 0.5,
        onComplete: () => {

          this.finishPhase()

        }
      }
    )

  }

  /**
   * Nastavenie kamery na replay
   */
  public changeCameraToReplay(): void {

    cameraManager.setState(CameraStates.discipline)
    this.resetCameraAndWrapper()
    this.changeCameraToReplayNextCameraMove = false
    const {
      idealOffsets,
      idealLookAt,
      coefSize,
      changeLerp
    } = customCameraConfig.replay

    const idealOffset = idealOffsets[Math.floor(Math.random() * idealOffsets.length)]

    player.changeCameraSettings(
      idealOffset,
      idealLookAt,
      coefSize,
      changeLerp
    )

  }

  /**
   * Replay
   */
  public replay(): void {

    console.log('REPLAY')

    this.targetHit = false

    pigeon.reset()

    pigeon.releasePigeonReplay()

    this.isReplay = true

  }

  /**
   * Ukoncene fazy
   */
  public finishPhase = (): void => {

    actionButtonState().isShoot = false
    this.delayFinishTween?.kill()
    pigeon.stopTweenPigeonBreak()
    this.isReplay = false
    if (
      corePhasesManager.disciplineActualAttempt % corePhasesManager.provisionalResultsFrequency === 0 &&
      !modes.isTutorial()
    ) {

      this.unlockPointer()

    }
    uiState().showEsc = false
    if (this.ended) return

    this.ended = true
    console.warn('shooting phase ended')

    // resetujeme tiene
    worldEnv.manageShadowsPosition()

    this.lockedCameraMove = true

    if (this.isInEmotion) {

      console.log('NOT IN EMOTION')
      this.isInEmotion = false
      player.changeCameraSettings(
        customCameraConfig.start.idealOffset,
        customCameraConfig.start.idealLookAt,
        customCameraConfig.start.coefSize,
        customCameraConfig.start.changeLerp,
        true
      )

    } else {

      this.resetCameraAndWrapper()

    }

    this.callbackEnd()

  }

  /**
   * Setter
   * @param value - bool
   */
  public setReady(value: boolean): void {

    this.shouldBeLocked = true
    this.ready = value
    let timeActive = true
    if (modes.isTutorial()) {

      timeActive = !tutorialFlow.firstAttempt

    }
    timeLimitManager.setActive(timeActive)
    windState().showWind = timeActive
    uiState().blueBoxTextType = BlueBoxTextType.callTarget
    shellsState().$patch({
      shellsLeft: 2,
      showShells: true
    })
    actionButtonState().disabled = false

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.ended = false
    this.released = false
    this.quality = 0
    this.frameCounter = 0
    this.wrapper = new ObjectWrapper()
    this.ended = false
    this.playerPositionOriginal.set(0, 0, 0)
    this.playerRotationOriginal.set(0, 0, 0)
    aimingDirectionManager.reset()
    this.shotsFired = 0
    this.targetHit = false
    this.ready = false
    this.minitableShown = false
    this.shotBalls.clear()
    this.isLocked = false
    this.shouldBeLocked = false
    this.missCallAudioPlayed = false
    this.opponentsScoreChanged = false
    this.emotionMessagesCount = 0
    this.emotionMessagesSkippable = false
    this.unloadingTween?.kill()
    this.emotionTween?.kill()

  }

}
