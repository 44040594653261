import {
  directionsState,
  speedmeterState,
  timeState
} from '@/stores'
// import { LoadingState } from '@powerplay/core-minigames-ui-ssm'
import {
  playersManager,
  settings,
  SettingsTypes,
  timeManager,
  type PlayerInfoForTable,
  corePhasesManager
} from '@powerplay/core-minigames'
import type { MiniTableRowData } from '@powerplay/core-minigames-ui-ssm/ssm-dist/src/ui/common/MiniTable/MiniTable.vue'
import { player } from '../entities/athlete/player'
import {
  fpsLookerState,
  gameSettingsState
} from '@powerplay/core-minigames-ui-ssm'
import { getActivePinia } from 'pinia'

export interface FpsDataVuex {
  averageFps: number
  actualFps: number
  actualAverageFps: number
}

export interface SpeedData {
  visible: boolean
  speed: string | number
}

export class StateManager {

  public getFpsStarted(): boolean {

    return fpsLookerState().isStarted ?? false

  }

  public setFpsData(fpsData: FpsDataVuex): void {

    fpsLookerState().$patch(fpsData)

  }

  public allowDirectionState(): void {

    directionsState().show = true

  }

  public setBeforeGameStartPhase(): void {

    gameSettingsState().$patch({
      graphicsSettings: settings.getSetting(SettingsTypes.quality),
      graphicsAuto: settings.getSetting(SettingsTypes.qualityAuto) === 1,
      volume: settings.getSetting(SettingsTypes.sounds) === 1,
      isLeft: settings.getSetting(SettingsTypes.isLeft) === 1,
      controlsType: settings.getSetting(SettingsTypes.controlsType),
      aimSensitivity: settings.getSetting(SettingsTypes.aimSensitivity),
      vibrations: settings.getSetting(SettingsTypes.vibrations) === 1,
      oneFinger: settings.getSetting(SettingsTypes.oneFinger) === 1,
    })

  }

  public setUpdateTimeState(): void {

    timeState().time = timeManager.getGameTimeInFormat(1)

  }

  public hideSpeedVisibility(): void {

    speedmeterState().visible = false

  }

  public updateSpeedData(speedData: SpeedData): void {

    speedmeterState().$patch({
      speed: Number(speedData.speed),
      visible: speedData.visible
    })

  }

  /**
   * Vytiahnutie dat pre minitable
   */
  public static getDataForMiniTable(playerPoints = 0) {

    const rowsData = [] as MiniTableRowData[]

    const standings = playersManager.getStandings()
    const playerPosition = standings.filter((value) => value.playable)[0].position

    let indexes = []

    if (playerPosition < 4) {

      indexes = [0, 1, 2, 3]

    } else if (playerPosition === standings.length) {

      indexes = [0, playerPosition - 3, playerPosition - 2, playerPosition - 1]

    } else {

      indexes = [0, playerPosition - 2, playerPosition - 1, playerPosition]

    }

    indexes.forEach((index) => {

      if (standings[index]) {

        const minitableRowData = this.getMiniTableDataFromPlayerInfo(standings[index], playerPoints)
        if (minitableRowData.textRight !== '') rowsData.push(minitableRowData)

      }

    })

    return rowsData

  }

  /**
   * Namapovanie dat pre minitable
   * @param playerInfo - playerInfo pre mapovanie
   * @param playerPoints - body posledneho pokusu hraca
   * @returns MiniTableRowData
   */
  private static getMiniTableDataFromPlayerInfo(playerInfo: PlayerInfoForTable, playerPoints: number) {

    const lastAttempt = playerInfo.uuid === player.uuid ?
      `+${ playerPoints}` :
      `+${
        playersManager.getPlayerById(playerInfo.uuid)?.resultsArr?.[corePhasesManager.disciplineActualAttempt - 1].main
      }` || ''
    return {
      position: playerInfo.position,
      country: playerInfo.country,
      countryString: playerInfo.countryString,
      player: {
        name: playerInfo.name,
        isPlayer: playerInfo.playable
      },
      textRight: String(playerInfo.result),
      lastAttempt: corePhasesManager.disciplineActualAttempt === 1 ? '' : lastAttempt
    } as MiniTableRowData

  }

  /**
   * Resetovanie pinie
   */
  public resetPinia(): void {

    const exceptions = ['tutorialState', 'inputsState', 'mobileState', 'loadingState', 'gameSettingsState']

    // eslint-disable-next-line
    // @ts-ignore
    getActivePinia()?._s.forEach((s: Store) => {

      if (!exceptions.includes(s.$id)) s.$reset()

    })

  }

}

export const stateManager = new StateManager()
