import {
  timeManager,
  audioManager,
  CustomEvents,
  corePhasesManager,
  playersManager,
  gsap,
  modes,
  fpsManager,
  cameraManager
} from '@powerplay/core-minigames'
import {
  DisciplinePhases,
  TutorialEventType,
  AudioNames,
  AudioGroups
} from '../types'
import { StartPhaseManager } from './StartPhase/StartPhase'
import { FinishPhaseManager } from './FinishPhase/FinishPhase'
import { player } from '../entities/athlete/player'
import { trainingResultsState } from '@/stores'
import { endManager } from '../EndManager'
import { trainingTasks } from '../modes/training'
import { aimingDirectionManager } from './AimPhase/AimingDirectionManager'
import { wind } from '../entities/athlete/Wind'
import { timeLimitManager } from '../TimeLimitManager'
import { AimPhase } from './AimPhase/AimPhase'
import { customCameraConfig } from '../config'
import { opponentsManager } from '../entities/athlete/opponent/OpponentsManager'
import { tutorialFlow } from '../modes/tutorial/TutorialFlow'
import { pigeon } from '../entities/equipment/Pigeon'
import { waitingState } from '@powerplay/core-minigames-ui-ssm'
import { stateManager } from '../StateManager'

/**
 * Trieda pre spravu faz
 */
export class DisciplinePhasesManager {

  /** aktualna faza */
  public actualPhase = 0

  /** tween na nastartovanie fazoveho managera */
  private startDisciplineTween!: gsap.core.Tween

  /** Faza startu */
  public phaseStart!: StartPhaseManager

  /** Faza mierenia/strielania */
  public phaseAim!: AimPhase

  /** faza konca */
  public phaseFinish!: FinishPhaseManager

  /** Callback pre pripravenie hry tak, aby isiel dalsi pokus */
  private callbackPrepareGameForNextAttempt!: () => unknown

  /**
   * Vytvorenie a nastavenie veci
   * @param callbackPrepareGameForNextAttempt - Callback pre pripravenie hry na dalsi pokus
   */
  public create(callbackPrepareGameForNextAttempt: () => unknown): void {

    this.callbackPrepareGameForNextAttempt = callbackPrepareGameForNextAttempt
    this.createAllPhases()
    aimingDirectionManager.init()

  }

  /**
   * Vytvorenie menegerov faz
   */
  public createAllPhases(): void {

    this.phaseStart = new StartPhaseManager(() => {

      this.startDisciplinePhase(DisciplinePhases.aim)

    })

    this.phaseAim = new AimPhase(() => {

      this.startDisciplinePhase(DisciplinePhases.finish)

    })

    this.phaseFinish = new FinishPhaseManager(() => {

      console.log('dispatch end')

      waitingState().isWaiting = true

      if (corePhasesManager.disciplineActualAttempt >= 1) {

        waitingState().isWaiting = false
        this.callbackPrepareGameForNextAttempt()

      }

      if (corePhasesManager.disciplineActualAttempt >= corePhasesManager.disciplineAttemptsCount) {

        tutorialFlow.eventActionTrigger(TutorialEventType.badFinish)

      }
      window.dispatchEvent(new CustomEvent(CustomEvents.finishDisciplinePhase))

    })

  }

  /**
   * Zistenie, ci jedna z faza je aktualna faza
   * @param phase - Pole faz na skontrolovanie
   * @returns True, ak je jedna z faz aktualna
   */
  public oneOfPhaseIsActual(phases: DisciplinePhases[]): boolean {

    return phases.includes(this.actualPhase)

  }

  public getActualPhase(): DisciplinePhases {

    return this.actualPhase

  }

  /**
   * Spustenie fazy
   * @param phase - Cislo fazy
   */
  public startDisciplinePhase(phase: DisciplinePhases): void {

    this.actualPhase = phase

    if (phase === DisciplinePhases.start) this.phaseStart.startPhase()
    if (phase === DisciplinePhases.aim) this.phaseAim.startPhase()
    if (phase === DisciplinePhases.finish) this.phaseFinish.startPhase()

  }

  /**
   * Update aktualnej fazy kazdy frame
   */
  public update(): void {

    if (this.actualPhase === DisciplinePhases.start) this.phaseStart.update()
    if (this.actualPhase === DisciplinePhases.aim) this.phaseAim.update()
    if (this.actualPhase === DisciplinePhases.finish) this.phaseFinish.update()

  }

  /**
   * rekurzivne ukoncime vsetky fazy
   */
  public disciplinePrematureEnd = async (): Promise<void> => {

    this.actualPhase = DisciplinePhases.end

    audioManager.stopAudioByGroup(AudioGroups.audience)
    audioManager.play(AudioNames.audienceHyped)

    corePhasesManager.disciplineActualAttempt = corePhasesManager.disciplineAttemptsCount
    playersManager.setStandings()
    console.log('STANDINGS', playersManager.getStandings())

    fpsManager.pauseCounting()

    const isFinished = false

    // pri treningu musime dokoncit udaje
    trainingTasks.saveLastTasksValues()

    // posleme udaje
    endManager.sendLogEnd()
    endManager.sendSaveResult()

    // reset states
    stateManager.resetPinia()

    waitingState().isWaiting = true
    if (!isFinished || corePhasesManager.firstInstructions) {

      trainingResultsState().isDisabledPlayAgain = true

    }
    // stopneme vsetky animacne callbacky
    if (player.animationsManager) player.animationsManager.removeCallbacksFromAllAnimations()

    disciplinePhasesManager.phaseAim.unlockPointer()

  }

  /**
   * Nastartovanie disciplinoveho fazoveho managera
   */
  public setStartPhase = (): void => {

    // v treningu musime spravit nejake upravy, aby vsetko fungovalo ako malo
    if (modes.isTrainingMode()) {

      if (customCameraConfig.start.enabled) player.setGameCameraSettings(undefined, true)
      // cameraManager.setState(CameraStates.intro)
      cameraManager.playTween(false)

    }

    if (modes.isTutorial() && corePhasesManager.disciplineActualAttempt === 1) {

      this.startStartPhase()
      return

    }

    // musime tu dat mensi delay, lebo mozeme skipovat este nejake fazy predtym
    this.startDisciplineTween = gsap.to({}, {
      duration: modes.isTutorial() ? 0.01 : 0.2,
      onComplete: () => {

        this.startStartPhase()

      }
    })

  }

  /**
   * Spustenie start phase
   */
  private startStartPhase(): void {

    const phase = DisciplinePhases.start
    this.startDisciplinePhase(phase)

  }

  /**
   * resetovanie pokusu hry
   * @param hardReset - True, ak sa resetuje na dalsiu hru, nie iba na dalsi pokus v hre
   */
  public resetAttempt(hardReset = false): void {

    console.log('reseting attempt')

    this.phaseStart.reset()
    this.phaseAim.reset()
    this.phaseFinish.reset()

    player.reset(hardReset)
    opponentsManager.reset(hardReset)
    wind.reset()
    timeLimitManager.reset()
    pigeon.reset()

    timeManager.reset()

  }

  /**
   * Checker tretieho pokusu
   * @returns ci je treti pokus
   */
  public isFifthAttempt(): boolean {

    return (corePhasesManager.disciplineActualAttempt - 1) % corePhasesManager.provisionalResultsFrequency === 0

  }

  /**
   * Reinstancovanie manazerov
   */
  public reset(): void {

    this.createAllPhases()
    this.resetAttempt(true)
    endManager.prematureEnded = false

  }

}

export const disciplinePhasesManager = new DisciplinePhasesManager()
