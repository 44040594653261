import { trainingResultsState } from '@/stores'
import {
  corePhasesManager,
  modes,
  playersManager,
  requestManager,
  type TrainingDataFromResultsRequest,
  trainingManager,
  gameStats,
  type PlayerInfo
} from '@powerplay/core-minigames'
import { tutorialObjectives } from './modes/tutorial/TutorialObjectives'
import type {
  OpponentResult,
  SaveResultsDataToSend
} from './types'
import { tutorialFlow } from './modes/tutorial/TutorialFlow'
import { player } from './entities/athlete/player'
import { waitingState } from '@powerplay/core-minigames-ui-ssm'

/**
 * Trieda pre koniec discipliny
 */
export class EndManager {

  /** ci uz bol result poslany alebo nie */
  private resultSent = false

  /** ci sa skoncilo predcasne */
  public prematureEnded = false

  /**
   * Poslanie requestu pre konecne logovanie
   */
  public sendLogEnd(): void {

    // ak uz mame nastavene, tak uz viac nenastavujeme, prip nastavujeme az na konci
    if (
      Object.keys(gameStats.getDisciplineDataToLog()).length > 0 ||
      corePhasesManager.disciplineActualAttempt < corePhasesManager.disciplineAttemptsCount
    ) return

    const pointsArr = playersManager.players[0].resultsArr?.map((object) => object.main) ?? [0, 0, 0, 0, 0, 0]

    // zaznamename nejake info pre logy
    gameStats.setDisciplineDataToLog({
      points: pointsArr,
      pointsTotal: pointsArr.reduce((acc, curVal) => acc + curVal, 0),
      dnf: playersManager.dnf,
      playerPosition: playersManager.getPlayerActualPosition(),
      trainingTasks: modes.isTrainingMode() ?
        trainingManager.getTrainingTasks().map(task => task.value) :
        [],
      tutorialData: modes.isTutorial() ? this.getTutorialLogs() : []
    })

    console.log('LOG to send', gameStats.getDisciplineDataToLog())

  }

  /**
   * ziskame objekt tutorialovych logov
   * @returns - objekt tutorialovych logov
   */
  private getTutorialLogs(): (number | boolean)[] {

    /**
     * completed - Ci hrac uspesne dokoncil vsetky ulohy [boolean]
     * exited - Ci bol ukonceny tutorial [boolean]
     */

    return [
      tutorialObjectives.isAllObjectivesPassed(), // completed
      tutorialFlow.pigeonReleased, // numberOfTargetsReleased
      tutorialFlow.pigeonsHit, // numberOfHits
      tutorialFlow.message7Count, // noShot
      tutorialFlow.message8Count, // oneShot
      this.prematureEnded, // exited
    ]

  }

  /**
   * Vybratie dat a poslanie do funkcie z core-minigames
   */
  public sendSaveResult(): void {

    // ak uz bol result poslany, neposielame ho znova
    if (this.resultSent) return

    // TODO TEMP - zatial takto, ked bude hotovy tutorial, tak sa to bude posielat tam
    requestManager.sendTutorialRequest()
    if (modes.isTutorial()) return

    // musime vybrat to lepsie umiestnenie a iba to budeme riesit (kedze su zoradeni, zoberieme prveho)
    const playerStanding = playersManager.getStandings().filter(standing => standing.uuid === player.uuid)
    const results = playerStanding[0].resultsFull
    const pointsArr = results ? results.map((object) => Number(object.main)) : [0, 0, 0, 0, 0, 0]

    const opponentsResults = [] as OpponentResult[]
    playersManager.players.filter((playerInfo: PlayerInfo) => {

      return !playerInfo.playable

    }).forEach((playerInfo: PlayerInfo) => {

      const pointsArrOpponent = playerInfo.resultsArr?.map((object) => Number(object.main))
      const pointsTotalOpponent = pointsArrOpponent?.reduce((acc, curVal) => acc + Number(curVal), 0) ?? 0

      opponentsResults.push({
        uuid: playerInfo.uuid,
        points: pointsTotalOpponent
      })

    })

    const data: SaveResultsDataToSend = {
      points: pointsArr,
      pointsTotal: results ? pointsArr.reduce((acc, curVal) => acc + Number(curVal), 0) : 0,
      positions: playersManager.getPlayersPositions(),
      opponentsResults: modes.isTrainingMode() ? [] : opponentsResults,
      dnf: playersManager.dnf,
    }

    if (modes.isTrainingMode()) {

      data.trainingResults = trainingManager.getTrainingTasks().map(task => task.value)

    } else if (corePhasesManager.disciplineActualAttempt < corePhasesManager.disciplineAttemptsCount) {

      // ked este nie je posledny pokus, tak neposielame save results, ale v treningu ano
      waitingState().isWaiting = false

      return

    }

    this.resultSent = true

    console.log('data to send', data)

    requestManager.sendSaveResultsRequest(
      (dataCallback: TrainingDataFromResultsRequest | unknown) => {

        console.log('saveRequest Successful')

        if (modes.isTrainingMode()) {

          trainingResultsState().$patch({
            data: dataCallback as TrainingDataFromResultsRequest,
            bestScore: trainingManager.bestScore,
            dataSet: true
          })

        }

      },
      JSON.stringify(data)
    )

  }

  /**
   * Reset result
   */
  public reset(): void {

    this.resultSent = false

  }

}

export const endManager = new EndManager()
